<template>
  <div>
    <van-nav-bar
        title="经营贷"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <div>
      <div class="name">问题一：</div>
      <div class="timu">
        借款主体为？
      </div>
      <van-radio-group v-model="zhuti">
        <van-radio name="1" class="xuanxiang">A.个体工商户</van-radio>
        <van-radio name="2" class="xuanxiang">B.中小企业</van-radio>
      </van-radio-group>
    </div>

    <van-divider />

    <div>
      <div class="name">问题二：</div>
      <div class="timu">
        您的营业执照年限？
      </div>
      <van-radio-group v-model="nianxian">
        <van-radio name="1" class="xuanxiang">A.6个月以上</van-radio>
        <van-radio name="2" class="xuanxiang">B.1-2年</van-radio>
        <van-radio name="3" class="xuanxiang">C.2年以上</van-radio>
      </van-radio-group>
    </div>

    <van-divider />

    <div>
      <div class="name">问题三：</div>
      <div class="timu">
        您的抵押物性质？
      </div>
      <van-radio-group v-model="xingzhi">
        <van-radio name="1" class="xuanxiang">A.住宅</van-radio>
        <van-radio name="2" class="xuanxiang">B.别墅</van-radio>
        <van-radio name="3" class="xuanxiang">C.公寓</van-radio>
        <van-radio name="4" class="xuanxiang">D.商业</van-radio>
        <van-radio name="5" class="xuanxiang">E.写字楼</van-radio>
      </van-radio-group>
    </div>

    <van-divider />

    <div>
      <div class="name">问题四：</div>
      <div class="timu">
        您的抵押物预估价值？
      </div>
      <van-radio-group v-model="jiazhi">
        <van-radio name="1" class="xuanxiang">A.80万以下</van-radio>
        <van-radio name="2" class="xuanxiang">B.80-100万</van-radio>
        <van-radio name="3" class="xuanxiang">C.100万以上</van-radio>
      </van-radio-group>
    </div>

    <van-divider />

    <div>
      <div class="name">问题五：</div>
      <div class="timu">
        您的抵押物面积（m²）？
      </div>
      <van-field class="xuanxiang" style="width: 80%;" v-model="mianji" type="number" placeholder="抵押物面积（m²）" />
    </div>

    <van-divider />

    <div>
      <div class="name">问题六：</div>
      <div class="timu">
        个人征信/企业征信是否有逾期记录？
      </div>
      <van-radio-group v-model="yuqi">
        <van-radio name="1" class="xuanxiang">A.有</van-radio>
        <van-radio name="2" class="xuanxiang">B.无</van-radio>
      </van-radio-group>
    </div>

    <div style="text-align: center; margin-top: 20px; margin-bottom: 20px;">
      <van-button type="primary" @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>

import {RadioGroup, Radio, NavBar, Divider, Button, Dialog, Field} from 'vant';
export default {
  name: "jingyingdai",

  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [NavBar.name]: NavBar,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Field.name]: Field,
  },

  data() {
    return {
      zhuti: '',
      nianxian: '',
      xingzhi: '',
      jiazhi: '',
      yuqi: '',
      mianji: ''

    }
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },

    submit() {
      if (this.zhuti === null || this.zhuti === '') {
        Dialog.alert({
          message: '请选择借款主体！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.nianxian === null || this.nianxian === '') {
        Dialog.alert({
          message: '请选择营业执照年限！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.xingzhi === null || this.xingzhi === '') {
        Dialog.alert({
          message: '请选择抵押物性质！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.jiazhi === null || this.jiazhi === '') {
        Dialog.alert({
          message: '请选择抵押物预估价值！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.mianji === null || this.mianji === '') {
        Dialog.alert({
          message: '请输入抵押物面积！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.yuqi === null || this.yuqi === '') {
        Dialog.alert({
          message: '请确认个人征信/企业征信是否有逾期记录！',
        }).then(() => {
          // on close
        });
        return;
      }

      if (this.nianxian === '1') {
        window.location.href = "https://100001427355.retail.n.weimob.com/saas/retail/100001427355/0/shop/index?id=13000781"
      } else {
        window.location.href = "https://100001427355.retail.n.weimob.com/saas/retail/100001427355/0/shop/index?id=13002639"
      }

    }
  }

}
</script>

<style scoped>
.name {
  font-weight: bold;
  font-size: 25px;
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.timu {
  font-weight: bold;
  font-size: 18px;
  margin-left: 10%;
  margin-bottom: 5%;
}

.xuanxiang {
  margin-left: 10%;
  margin-bottom: 10px;
  margin-right: 10%;
}
</style>